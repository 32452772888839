* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

body {
  background: #000;
  color: #323232;
  -webkit-font-smoothing: antialiased;
}

body,
input,
button {
  font-family: 'Poppins', sans-serif;
  font-size: clamp(11px, 1vw + 0.1rem, 16px);
  font-weight: 500;
  border: 1px solid rgba(0, 0, 0, 0.25);
}

h1,
h2,
h3 h4,
h5,
h6,
strong {
  font-weight: 700;
}

button {
  cursor: pointer;
  background: transparent;
  border: none;
}

html,
body {
  min-height: 100vh;
  min-width: 100vw;
}

#root {
  height: 100vh;
  width: 100vw;
}
